import React, { useState } from 'react'
import { css } from '@emotion/react'
import { useStaticQuery, graphql } from "gatsby"
import { Box } from '@material-ui/core';
import AppStoreSvg from '../../assets/svg/applestore.png'
import AppAndroidSvg from '../../assets/svg/googleplay.png'
import { withThemeProps } from '@findep/microfronts-core';
import { CataloguesService } from '../../services/LandingV4'
import PropTypes from 'prop-types'
import { Backdrop } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress';

const FelicidadesApp = (props) => {
  const {TitleAndroid, TitleApple}= props
  const [bdActive, setBdActive]=useState(false);

    const data = useStaticQuery(graphql`
    query {
        allComponentsJson(filter: {component: {eq: "felicidades-app"}}) {
          edges {
            node {
              id
              url {
                url
                company
                so
              }
            }
          }
        }
      }
  `)
  let url = ''
  if(props.detectedIos === "DESCKTOP"){
      url = data.allComponentsJson.edges[0].node.url.filter(item => item.company === props.company)
  }else{
    url = data.allComponentsJson.edges[0].node.url.filter(item => item.company === props.company && item.so === props.detectedIos)
  }
      
  const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `

 async function linkAgent(link){    
    setBdActive(true)
    let localFlujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
    const catalogos = new CataloguesService()
    const data = await catalogos.putAgent(localFlujo.creditApplicationId, props.mobile)
    // console.log("enviando servicio=>", data)
    // if(data.status===204){window.open(link)}
    if(data.status===204){            
            if(props.mobile){                
                window.open(link)
                setBdActive(false)
            }else{                      
                props.handleModal(false)                
                setBdActive(false)
            }
        }else{ 
          props.setSizeError(true)
        }
  }
  
  const Link = ({url: customUrl, imgSrc, imgAlt, customCss,textAlt}) => {        

    return (
      <a 
        css={customCss}
        href={props.mobile ?  customUrl: 'javascript:void(0)'}
        target="_parent" 
        rel="noopener noreferrer" 
        onClick={()=> linkAgent(customUrl)}       
      >
        <img 
          alt={imgAlt}
          src={imgSrc}
          width="100%"
        />
      </a>
    )
  }

  const IosLink = ({url: customUrl}) => (
    <Link 
      url={customUrl}
      imgAlt={TitleApple}
      imgSrc={AppStoreSvg}
      customCss={css`width: 77%;`}
    />
  )

  const AndroidLink = ({url: customUrl}) => (
    <Link 
      url={customUrl}
      imgAlt={TitleAndroid}
      imgSrc={AppAndroidSvg}
      customCss={css`width: 77%;`}
    />
  )

    return (
      <div>
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">

            {
              url.map(node => (
                node.so === 'ANDROID' ? <AndroidLink url={props?.links} /> : <IosLink url={props?.links} />
              ))
            }
            </Box>
            <Backdrop css={bDrop} open={bdActive} >
                <CircularProgress color="primary" />
            </Backdrop>
      </div>
        
    );
}

export default withThemeProps(FelicidadesApp, 'VDPNFelicidadesApp')

FelicidadesApp.propTypes = {
    TitleAndroid: PropTypes.string,
    TitleApple: PropTypes.string,
    // TitleButton: PropTypes.string,
}

FelicidadesApp.defaultProps = {
TitleAndroid: "Disponible en Google Play",
TitleApple: "Descárgalo en el App Store"
}

